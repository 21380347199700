import React, { useContext, useCallback, useEffect } from "react";
import Login from './Login'
import Logout from './Logout'
import UserContext from '../context/UserContext';
import MintSection from "./MintSection";

export default (props) => {
    const { user, assetId } = useContext(UserContext);

    return (
        <div className="container-center-horizontal">
            <div className="phone-home screen" name="form5" action="form5" method="post">
                <div className="overlap-group7-2">
                    <img className="group-16-2" src="assets/img/group-16-2@2x.svg" />
                    <div className="overlap-group-3">
                        {/* {!user && <Login />}
                        {user && <Logout />} */}
                        <div className="connect-wallet">
                            <a href="#collections-mobile" className="nolink" id="mintnow-phone">Mint Now</a>
                        </div>
                    </div>
                    <div className="overlap-group1-2">
                        <div className="suvigya-sharma-4 ekselldisplay-medium-black-12px">Suvigya Sharma</div>
                        {/* <div className="collections-2 rubik-light-black-10px"><a href="#collections-mobile" className="nolink">Collections</a></div> */}
                        <img className="line-2-2" src="assets/img/line-2@2x.svg" />
                    </div>
                </div>
                <div className="rectangle-4-2"></div>
                <img className="frame-4" src="assets/img/frame-4@2x.svg" />
                <p className="text-33 rubik-light-black-10px">
                    Suvigya Sharma is an Indian artist, painter, fashion designer, who does miniature paintings, Tanjore painting, fresco work and portraits. He has worked in restoring fresco painting at City Palace at Jaipur, Jama Masjid and Singapore Art Museum. His works fall under miniature art work in India.
                </p>
                <div className="group-73 rubik-light-black-10px">
                    {/* <div className="artist-2">ARTIST</div>
                    <div className="suvigya-sharma-5">@suvigya Sharma</div> */}
                </div>
                <div className="group-69-2 smart-layers-pointers">
                    <div className="overlap-group2-2">
                        <div className="start-collecting-2"><a className="collection-link" href="#collections-mobile">Start Collecting</a></div>
                    </div>
                </div>
                <div className="group-75">
                    <div className="overlap-group3-2">
                        <img className="line-1-2" src="assets/img/line-1-6@2x.svg" />
                        <p className="text-34 rubik-medium-white-12px">The future of art collecting</p>
                        <p className="text-35 rubik-light-white-10px">
                            Browse and build your collection of the world’s most cutting-edge digital art
                        </p>
                    </div>
                </div>
                <div className="limited-collections-1 ekselldisplay-medium-black-18px">Limited Collection</div>
                <div className="text-36 ekselldisplay-medium-black-18px">Buy the NFT + Get a physical painting</div>
                <div className="special-1-of-1-mint-2 ekselldisplay-medium-black-18px">Special 1 of 1 Mint</div>
                <p className="text-37 rubik-light-black-10px">
                    For our first launch of RTALabs, Indian artist, painter Suvigya Sharma launches an NFT show featuring 10 of his art pieces -- Alpha, Beta,charlie, tokin. Each art minted is a limited edition of 10 NFTs. It also comes with a unique one-of-one physical
                    piece available to owner of the NFT token.<br /><br />Each NFT will be accompanied by a physical version of the painting. Physical paintings will be given to those who hold the NFT on December 25th, 11:59pm IST
                </p>
                <div className="overlap-group10-2">
                    <div className="rectangle-9-2"></div>
                </div>
                <div className="rectangle-10-2"></div>
                <div className="rectangle-11-2"></div>
                <div className="rectangle-13-2"></div>
                <div className="rectangle-12-2"></div>
                <div className="rectangle-14-2"></div>
                <div className="overlap-group9-2">
                    <div className="rectangle-16-2"></div>
                </div>
                <p className="text-38 rubik-light-black-10px">
                    <span className="rubik-light-black-10px">Suvigya has worked on fresco in </span
                    ><span className="rubik-medium-black-10px">Jaipur</span
                    ><span className="rubik-light-black-10px"> and other places like Havelis in </span
                    ><span className="rubik-medium-black-10px">Udaipur</span><span className="rubik-light-black-10px"> and </span
                    ><span className="rubik-medium-black-10px">Kishangarh</span><span className="rubik-light-black-10px">, </span
                    ><span className="rubik-medium-black-10px">Jama Masjid&#39;s</span
                    ><span className="rubik-light-black-10px"> gold leafing, a few section of </span
                    ><span className="rubik-medium-black-10px">Jaipur City Palace</span
                    ><span className="rubik-light-black-10px"
                    >, Bungalows, Dargah, heritage properties in different part of India.<br /><br />Some of his clients for
                        whom he painted includes </span
                    ><span className="rubik-medium-black-10px">L. N. Mittal</span><span className="rubik-light-black-10px">, </span
                    ><span className="rubik-medium-black-10px">Sachin Tendulkar</span><span className="rubik-light-black-10px">, </span
                    ><span className="rubik-medium-black-10px">Narendra Modi</span><span className="rubik-light-black-10px">, </span
                    ><span className="rubik-medium-black-10px">Priyanka Chopra</span><span className="rubik-light-black-10px">, </span
                    ><span className="rubik-medium-black-10px">Aditya Chopra</span><span className="rubik-light-black-10px">, </span
                    ><span className="rubik-medium-black-10px">Rani Mukherji</span><span className="rubik-light-black-10px">, </span
                    ><span className="rubik-medium-black-10px">Sanjay Dutt</span><span className="rubik-light-black-10px">, </span
                    ><span className="rubik-medium-black-10px">Manyata Dutt</span><span className="rubik-light-black-10px">, </span
                    ><span className="rubik-medium-black-10px">Kangana Ranaut</span
                    ><span className="rubik-light-black-10px"> and business families such as the </span
                    ><span className="rubik-medium-black-10px">Ambanis</span><span className="rubik-light-black-10px">, </span
                    ><span className="rubik-medium-black-10px">the Birlas</span><span className="rubik-light-black-10px">, </span
                    ><span className="rubik-medium-black-10px">the Burmans</span><span className="rubik-light-black-10px">, </span
                    ><span className="rubik-medium-black-10px">the Singhanias</span><span className="rubik-light-black-10px">, </span
                    ><span className="rubik-medium-black-10px">the Piramal</span><span className="rubik-light-black-10px">, </span
                    ><span className="rubik-medium-black-10px">the Bajaj</span
                    ><span className="rubik-light-black-10px">. Suvigya has also painted for </span
                    ><span className="rubik-medium-black-10px">PM Narendra Modi</span><span className="rubik-light-black-10px">, </span
                    ><span className="rubik-medium-black-10px">Barack Obama</span><span className="rubik-light-black-10px">, </span
                    ><span className="rubik-medium-black-10px">Hillary Clinton</span><span className="rubik-light-black-10px"> and </span
                    ><span className="rubik-medium-black-10px">Dalai Lama</span
                    ><span className="rubik-light-black-10px"
                    >.<br /><br />He has been exhibited in galleries and museums such as </span
                    ><span className="rubik-medium-black-10px">Academy of Fine Arts</span
                    ><span className="rubik-light-black-10px">, Kolkata, </span
                    ><span className="rubik-medium-black-10px">Cymroza Art Gallery</span><span className="rubik-light-black-10px">, </span
                    ><span className="rubik-medium-black-10px">Kamalnayan Bajaj Art Gallery</span
                    ><span className="rubik-light-black-10px">, </span
                    ><span className="rubik-medium-black-10px">Chitrakoot Art Gallery</span
                    ><span className="rubik-light-black-10px">, </span
                    ><span className="rubik-medium-black-10px">India Habitat Centre</span
                    ><span className="rubik-light-black-10px"> and </span
                    ><span className="rubik-medium-black-10px">Artisans Art gallery</span
                    ><span className="rubik-light-black-10px">.</span>
                </p>
                <p className="text-39 rubik-light-black-10px">A hand-painted physical painting is included with this 1 of 1 NFT</p>
                <div className="mint-section" id="collections-mobile">
                    <MintSection assetId={assetId} key={"mobile"} />
                </div>
                <div className="group-78">
                    <div className="overlap-group6-2">
                        <img className="this-is-the-new-ative-economy-1-2" src="assets/img/this-is-the-new-creative-economy-1-2@2x.svg" />
                        <p className="text-45 rubik-light-white-10px">
                            We’re bringing digital creators, crypto natives, and collectors together to move culture forward.
                        </p>
                    </div>
                    <div className="group-77">
                        <div className="group-68-2 rubik-light-white-10px">
                            {/* <div className="privacy-policy-1">Contact</div> */}
                            <div className="terms-conditions-1">contact@rtalabs.xyz</div>
                        </div>
                        <img className="line-3-2" src="assets/img/line-3-2@2x.svg" />
                        <div className="group-67-2">
                            <img className="x2021-twitter-logo-white-1-2" src="assets/img/2021-twitter-logo---white-1-2@2x.png" />
                            <img className="frame-5" src="assets/img/frame-5@2x.svg" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}