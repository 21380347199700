import React, { useContext }  from "react";
import UserContext from '../context/UserContext';

function Logout() {
    const { logout } = useContext(UserContext)

    const logoutPressed = () => {
        console.error("Logout!");
        console.error(logout);
        logout();
    };

    return (
      <React.Fragment>
          <div className="connect-wallet" onClick={logoutPressed}>
            Logout
          </div>
      </React.Fragment>
    );
  };
  
  export default Logout;
