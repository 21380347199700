import React, { useContext, useCallback, useEffect } from "react";
import Login from './Login'
import Logout from './Logout'
import UserContext from '../context/UserContext';
import MintSection from './MintSection';
export default (props) => {
    const { user, loginErrorMessage, assetId } = useContext(UserContext);

    return (
        < div className="container-center-horizontal" >
            <div className="desktop-home screen" name="form4" action="form4" method="post">

                <div className="overlap-group3">
                    <div className="overlap-group11">
                        <div className="overlap-group">
                            {/* {!user && <Login />}
                            {user && <Logout />} */}
                            <div className="connect-wallet">
                                <a href="#collections-desktop" className="nolink" id="mintnow-desktop">Mint Now</a>
                            </div>
                        </div>
                        <div className="suvigya-sharma ekselldisplay-medium-black-12px">Suvigya Sharma</div>
                        {/* <div className="collections rubik-light-black-12px"><a href="#collections-desktop" className="nolink">Collections</a></div> */}
                        <img className="line-2" src="assets/img/line-2@2x.svg" />
                    </div>
                    <a href="/"><img className="group-16" src="assets/img/group-16-1@2x.svg" /></a>
                </div>
                {loginErrorMessage}

                <div className="overlap-group4">
                    <img className="group-23" src="assets/img/group-23-1@2x.svg" />
                    <div className="group-68 rubik-light-white-14px">
                        <div className="privacy-policy-1">contact@rtalabs.xyz</div>
                        {/* <div className="terms-conditions-1">contact@rtalabs.xyz</div> */}
                    </div>
                    <div className="group-67">
                        <img className="x2021-twitter-logo-white-1" src="assets/img/2021-twitter-logo---white-1@2x.png" />
                        <img className="frame" src="assets/img/frame-3@2x.svg" />
                    </div>
                    <img className="line-3" src="assets/img/line-3-1@1x.svg" />
                </div>
                <img className="frame-1" src="assets/img/frame-2@2x.svg" />
                <div className="rectangle-4" ><img src="assets/img/mask-group-7@1x.png" /></div>
                {/* <div className="artist rubik-light-black-10px">ARTIST</div>
                <div className="suvigya-sharma-1 rubik-light-black-10px">@suvigya Sharma</div> */}
                <p className="text-17 rubik-light-black-14px">
                    <p>Suvigya Sharma (born 28 July 1983) is an Indian artist, painter, fashion designer, who does miniature paintings, Tanjore painting, fresco work and portraits.</p>
                    <p>He has worked in restoring fresco painting at City Palace at Jaipur, Jama Masjid and Singapore Art Museum. His works fall under miniature art work in India.</p>
                    <p>In February, 2016, Suvigya Sharma his art work Timeless Miniature Art was showcased at Make in India event, with a live demonstration of Miniature-painting technologies in Mumbai.</p>
                </p>
                <p className="text-18 rubik-light-black-14px">
                    For our first launch of RTALabs, Indian artist, painter Suvigya Sharma launches an NFT show featuring 10 of his art pieces -- Alpha, Beta,charlie, tokin. Each art minted is a limited edition of 10 NFTs. It also comes with a unique one-of-one physical
                    piece available to owner of the NFT token.<br /><br />Each NFT will be accompanied by a physical version of the painting. Physical paintings will be given to those who hold the NFT on December 25th, 11:59pm IST
                </p>
                <p className="text-19 rubik-light-black-14px">
                    <span className="rubik-light-black-14px">Suvigya has worked on fresco in </span
                    ><span className="rubik-medium-black-14px">Jaipur</span
                    ><span className="rubik-light-black-14px"> and other places like Havelis in </span
                    ><span className="rubik-medium-black-14px">Udaipur</span><span className="rubik-light-black-14px"> and </span
                    ><span className="rubik-medium-black-14px">Kishangarh</span><span className="rubik-light-black-14px">, </span
                    ><span className="rubik-medium-black-14px">Jama Masjid&#39;s</span
                    ><span className="rubik-light-black-14px"> gold leafing, a few section of </span
                    ><span className="rubik-medium-black-14px">Jaipur City Palace</span
                    ><span className="rubik-light-black-14px"
                    >, Bungalows, Dargah, heritage properties in different part of India.<br /><br />Some of his clients for
                        whom he painted includes </span
                    ><span className="rubik-medium-black-14px">L. N. Mittal</span><span className="rubik-light-black-14px">, </span
                    ><span className="rubik-medium-black-14px">Sachin Tendulkar</span><span className="rubik-light-black-14px">, </span
                    ><span className="rubik-medium-black-14px">Narendra Modi</span><span className="rubik-light-black-14px">, </span
                    ><span className="rubik-medium-black-14px">Priyanka Chopra</span><span className="rubik-light-black-14px">, </span
                    ><span className="rubik-medium-black-14px">Aditya Chopra</span><span className="rubik-light-black-14px">, </span
                    ><span className="rubik-medium-black-14px">Rani Mukherji</span><span className="rubik-light-black-14px">, </span
                    ><span className="rubik-medium-black-14px">Sanjay Dutt</span><span className="rubik-light-black-14px">, </span
                    ><span className="rubik-medium-black-14px">Manyata Dutt</span><span className="rubik-light-black-14px">, </span
                    ><span className="rubik-medium-black-14px">Kangana Ranaut</span
                    ><span className="rubik-light-black-14px"> and business families such as the </span
                    ><span className="rubik-medium-black-14px">Ambanis</span><span className="rubik-light-black-14px">, </span
                    ><span className="rubik-medium-black-14px">the Birlas</span><span className="rubik-light-black-14px">, </span
                    ><span className="rubik-medium-black-14px">the Burmans</span><span className="rubik-light-black-14px">, </span
                    ><span className="rubik-medium-black-14px">the Singhanias</span><span className="rubik-light-black-14px">, </span
                    ><span className="rubik-medium-black-14px">the Piramal</span><span className="rubik-light-black-14px">, </span
                    ><span className="rubik-medium-black-14px">the Bajaj</span
                    ><span className="rubik-light-black-14px">. Suvigya has also painted for </span
                    ><span className="rubik-medium-black-14px">PM Narendra Modi</span><span className="rubik-light-black-14px">, </span
                    ><span className="rubik-medium-black-14px">Barack Obama</span><span className="rubik-light-black-14px">, </span
                    ><span className="rubik-medium-black-14px">Hillary Clinton</span><span className="rubik-light-black-14px"> and </span
                    ><span className="rubik-medium-black-14px">Dalai Lama</span
                    ><span className="rubik-light-black-14px"
                    >.<br /><br />He has been exhibited in galleries and museums such as </span
                    ><span className="rubik-medium-black-14px">Academy of Fine Arts</span
                    ><span className="rubik-light-black-14px">, Kolkata, </span
                    ><span className="rubik-medium-black-14px">Cymroza Art Gallery</span><span className="rubik-light-black-14px">, </span
                    ><span className="rubik-medium-black-14px">Kamalnayan Bajaj Art Gallery</span
                    ><span className="rubik-light-black-14px">, </span
                    ><span className="rubik-medium-black-14px">Chitrakoot Art Gallery</span
                    ><span className="rubik-light-black-14px">, </span
                    ><span className="rubik-medium-black-14px">India Habitat Centre</span
                    ><span className="rubik-light-black-14px"> and </span
                    ><span className="rubik-medium-black-14px">Artisans Art gallery</span
                    ><span className="rubik-light-black-14px">.</span>
                </p>
                <p className="text-20 rubik-light-black-14px">A hand-painted physical painting is included with this 1 of 1 NFT</p>


                <div className="mint-section" id="collections-desktop">
                    <MintSection assetId={assetId} key={"desktop"} />
                </div>


                <div className="release-date rubik-light-black-10px">RELEASE DATE</div>
                <p className="oct-10-2021-10-pm rubik-medium-black-12px">Oct 20, 2021, 10 PM</p>

                <div className="overlap-group7">
                    <div className="group-21">
                        <div className="overlap-group1">
                            <div className="learn-more">Learn More...</div>
                        </div>
                    </div>
                    <div className="group-18">
                        <img className="line-1" src="assets/img/line-1-3@2x.svg" />
                        <p className="text-26 rubik-medium-white-14px">The future of art collecting</p>
                        <p className="text rubik-light-white-12px">
                            Browse and build your collection of the world’s most cutting-edge digital art
                        </p>
                    </div>
                    <div className="group-19">
                        <img className="line-1" src="assets/img/line-1-3@2x.svg" />
                        <div className="text-28 rubik-medium-white-14px">Pioneering art market royalties</div>
                        <p className="text rubik-light-white-12px">
                            Artists receive continuous royalties for all secondary sales on their artworks – forever
                        </p>
                    </div>
                    <div className="group-20">
                        <img className="line-1" src="assets/img/line-1-3@2x.svg" />
                        <div className="built-for-longevity rubik-medium-white-14px">Built for <br />longevity</div>
                        <p className="text rubik-light-white-12px">
                            All transactions happen on-chain, creating a tamper-proof record of each artwork’s history
                        </p>
                    </div>
                </div>
                <div className="overlap-group6">
                    <img className="this-is-the-new-ative-economy-1" src="assets/img/this-is-the-new-creative-economy-1-1@2x.svg" />
                    <p className="text-32 rubik-light-white-14px">
                        We’re bringing digital creators, crypto natives, and collectors together to move culture forward.
                    </p>
                </div>
                <div className="group-69 smart-layers-pointers">
                    <div className="overlap-group2">
                        <div className="start-collecting ekselldisplay-medium-white-14px"><a className="collection-link" href="#collections-desktop">Start Collecting</a></div>
                    </div>
                </div>
                <div className="overlap-group8">
                    <div className="rectangle-9"></div>
                </div>
                <div className="rectangle-10"></div>
                <div className="rectangle-11"></div>
                <div className="overlap-group12">
                    <div className="overlap-group5">
                        <div className="rectangle-13"></div>
                        <div className="rectangle-15"></div>
                        <div className="rectangle-16"></div>
                    </div>
                    <div className="rectangle-14"></div>
                </div>
                <div className="rectangle-12"></div>
                <h1 className="title ekselldisplay-medium-black-48px">Limited Collection</h1>
                <div className="text-31 ekselldisplay-medium-black-48px">Buy the NFT + Get a physical painting</div>
                <div className="special-1-of-1-mint ekselldisplay-medium-black-48px">Special 1 of 1 Mint</div>
            </div>
        </div >
    )
}