import React, { useContext, useCallback, useEffect } from "react";
import Login from './Login'
import Logout from './Logout'
import UserContext from '../context/UserContext';
import MintSection from "./MintSection";

export default (props) => {
    const { user, assetId } = useContext(UserContext);

    return (
        < div className="container-center-horizontal" >
            <div className="ipad-home screen" name="form2" action="form2" method="post">
                <div className="overlap-group9-1">
                    <div className="overlap-group-2">
                        <div className="overlap-group1-1">
                            {/* {!user && <Login />}
                            {user && <Logout />} */}
                            <div className="connect-wallet">
                                <a href="#collections-tablet" className="nolink" id="mintnow-ipad">Mint Now</a>
                            </div>
                        </div>
                        <div className="suvigya-sharma-2 ekselldisplay-medium-black-12px">Suvigya Sharma</div>
                        {/* <div className="collections-1 rubik-light-black-12px"><a href="#collections-tablet" className="nolink">Collections</a></div> */}
                        <img className="line-2-1" src="assets/img/line-2@2x.svg" />
                    </div>
                    <a href="/"><img className="group-16-1" src="assets/img/group-16@2x.svg" /></a>
                </div>
                <img className="frame-2" src="assets/img/frame@2x.svg" />
                <p className="text-1-1">
                    Suvigya Sharma (born 28 July 1983) is an Indian artist, painter, fashion designer, who does miniature paintings, Tanjore painting, fresco work and portraits. He has worked in restoring fresco painting at City Palace at Jaipur, Jama Masjid and Singapore Art Museum. His works fall under miniature art work in India. In February, 2016, Suvigya Sharma his art work Timeless Miniature Art was showcased at Make in India event, with a live demonstration of Miniature-painting technologies in Mumbai.
                </p>
                <div className="rectangle-4-1"></div>
                {/* <div className="artist-1 rubik-light-black-10px">ARTIST</div> */}
                {/* <div className="suvigya-sharma-3 rubik-light-black-10px">@suvigya Sharma</div> */}
                <div className="release-date-1 rubik-light-black-10px">RELEASE DATE</div>
                <p className="oct-10-2021-10-pm-1 rubik-medium-black-12px">Oct 20, 2021, 10 PM</p>
                <div className="group-69-1 smart-layers-pointers">
                    <div className="overlap-group2-1">
                        <div className="start-collecting-1 ekselldisplay-medium-white-14px"><a className="collection-link" href="#collections-tablet">Start Collecting</a></div>
                    </div>
                </div>
                <div className="overlap-group7-1">
                    <div className="group-81">
                        <p className="text-2 rubik-medium-white-12px">The future of art collecting</p>
                        <p className="text-1 rubik-light-white-10px">
                            Browse and build your collection of the world’s most cutting-edge digital art
                        </p>
                        <img className="line-1-1" src="assets/img/line-1@2x.svg" />
                    </div>
                    <div className="group-82">
                        <div className="text-4 rubik-medium-white-12px">Pioneering art market royalties</div>
                        <p className="text-1 rubik-light-white-10px">
                            Artists receive continuous royalties for all secondary sales on their artworks – forever
                        </p>
                        <img className="line-1-1" src="assets/img/line-1@2x.svg" />
                    </div>
                    <div className="group-83">
                        <div className="built-for-longevity-1 rubik-medium-white-12px">Built for <br />longevity</div>
                        <p className="text-1 rubik-light-white-10px">
                            All transactions happen on-chain, creating a tamper-proof record of each artwork’s history
                        </p>
                        <img className="line-1-1" src="assets/img/line-1@2x.svg" />
                    </div>
                    <div className="group-21-1">
                        <div className="overlap-group3-1">
                            <div className="learn-more-1">Learn More...</div>
                        </div>
                    </div>
                </div>
                <div className="group-84">
                    <p className="text-7 rubik-light-black-12px">
                        For our first launch of RTALabs, Indian artist, painter Suvigya Sharma launches an NFT show featuring 10 of his art pieces -- Alpha, Beta,charlie, tokin. Each art minted is a limited edition of 10 NFTs. It also comes with a unique one-of-one physical
                        piece available to owner of the NFT token.<br /><br />Each NFT will be accompanied by a physical version of the painting. Physical paintings will be given to those who hold the NFT on December 25th, 11:59pm IST
                    </p>
                    <div className="limited-collections ekselldisplay-medium-black-30px">Limited Collection</div>
                </div>
                <div className="text-8 ekselldisplay-medium-black-30px">Buy the NFT + Get a physical painting</div>
                <div className="special-1-of-1-mint-1 ekselldisplay-medium-black-30px">Special 1 of 1 Mint</div>
                <div className="overlap-group12-1">
                    <div className="rectangle-9-1"></div>
                </div>
                <div className="rectangle-10-1"></div>
                <div className="rectangle-11-1"></div>
                <div className="overlap-group13">
                    <div className="overlap-group8-1">
                        <div className="rectangle-13-1"></div>
                        <div className="rectangle-15-1"></div>
                        <div className="rectangle-16-1"></div>
                    </div>
                    <div className="rectangle-14-1"></div>
                </div>
                <div className="rectangle-12-1"></div>
                <p className="text-9 rubik-light-black-12px">
                    <span className="rubik-light-black-12px">Suvigya has worked on fresco in </span
                    ><span className="rubik-medium-black-12px">Jaipur</span
                    ><span className="rubik-light-black-12px"> and other places like Havelis in </span
                    ><span className="rubik-medium-black-12px">Udaipur</span><span className="rubik-light-black-12px"> and </span
                    ><span className="rubik-medium-black-12px">Kishangarh</span><span className="rubik-light-black-12px">, </span
                    ><span className="rubik-medium-black-12px">Jama Masjid&#39;s</span
                    ><span className="rubik-light-black-12px"> gold leafing, a few section of </span
                    ><span className="rubik-medium-black-12px">Jaipur City Palace</span
                    ><span className="rubik-light-black-12px"
                    >, Bungalows, Dargah, heritage properties in different part of India.<br /><br />Some of his clients for
                        whom he painted includes </span
                    ><span className="rubik-medium-black-12px">L. N. Mittal</span><span className="rubik-light-black-12px">, </span
                    ><span className="rubik-medium-black-12px">Sachin Tendulkar</span><span className="rubik-light-black-12px">, </span
                    ><span className="rubik-medium-black-12px">Narendra Modi</span><span className="rubik-light-black-12px">, </span
                    ><span className="rubik-medium-black-12px">Priyanka Chopra</span><span className="rubik-light-black-12px">, </span
                    ><span className="rubik-medium-black-12px">Aditya Chopra</span><span className="rubik-light-black-12px">, </span
                    ><span className="rubik-medium-black-12px">Rani Mukherji</span><span className="rubik-light-black-12px">, </span
                    ><span className="rubik-medium-black-12px">Sanjay Dutt</span><span className="rubik-light-black-12px">, </span
                    ><span className="rubik-medium-black-12px">Manyata Dutt</span><span className="rubik-light-black-12px">, </span
                    ><span className="rubik-medium-black-12px">Kangana Ranaut</span
                    ><span className="rubik-light-black-12px"> and business families such as the </span
                    ><span className="rubik-medium-black-12px">Ambanis</span><span className="rubik-light-black-12px">, </span
                    ><span className="rubik-medium-black-12px">the Birlas</span><span className="rubik-light-black-12px">, </span
                    ><span className="rubik-medium-black-12px">the Burmans</span><span className="rubik-light-black-12px">, </span
                    ><span className="rubik-medium-black-12px">the Singhanias</span><span className="rubik-light-black-12px">, </span
                    ><span className="rubik-medium-black-12px">the Piramal</span><span className="rubik-light-black-12px">, </span
                    ><span className="rubik-medium-black-12px">the Bajaj</span
                    ><span className="rubik-light-black-12px">. Suvigya has also painted for </span
                    ><span className="rubik-medium-black-12px">PM Narendra Modi</span><span className="rubik-light-black-12px">, </span
                    ><span className="rubik-medium-black-12px">Barack Obama</span><span className="rubik-light-black-12px">, </span
                    ><span className="rubik-medium-black-12px">Hillary Clinton</span><span className="rubik-light-black-12px"> and </span
                    ><span className="rubik-medium-black-12px">Dalai Lama</span
                    ><span className="rubik-light-black-12px"
                    >.<br /><br />He has been exhibited in galleries and museums such as </span
                    ><span className="rubik-medium-black-12px">Academy of Fine Arts</span
                    ><span className="rubik-light-black-12px">, Kolkata, </span
                    ><span className="rubik-medium-black-12px">Cymroza Art Gallery</span><span className="rubik-light-black-12px">, </span
                    ><span className="rubik-medium-black-12px">Kamalnayan Bajaj Art Gallery</span
                    ><span className="rubik-light-black-12px">, </span
                    ><span className="rubik-medium-black-12px">Chitrakoot Art Gallery</span
                    ><span className="rubik-light-black-12px">, </span
                    ><span className="rubik-medium-black-12px">India Habitat Centre</span
                    ><span className="rubik-light-black-12px"> and </span
                    ><span className="rubik-medium-black-12px">Artisans Art gallery</span
                    ><span className="rubik-light-black-12px">.</span>
                </p>
                <p className="text-10 rubik-light-black-14px">A hand-painted physical painting is included with this 1 of 1 NFT</p>
                <div className="mint-section" id="collections-tablet">
                    <MintSection assetId={assetId} key={"tablet"} />
                </div>

                <div className="overlap-group11-1">
                    <img className="this-is-the-new-ative-economy-1-1" src="assets/img/this-is-the-new-creative-economy-1@2x.svg" />
                    <p className="text-16 rubik-light-white-12px">
                        We’re bringing digital creators, crypto natives, and collectors together to move culture forward.
                    </p>
                </div>
                <div className="overlap-group6-1">
                    <img className="group-23-1" src="assets/img/group-23@2x.svg" />
                    <div className="group-68-1 rubik-light-white-14px">
                        <div className="privacy-policy-1">Contact</div>
                        <div className="terms-conditions-1">contact@rtalabs.xyz</div>
                    </div>
                    <div className="group-67-1">
                        <img className="x2021-twitter-logo-white-1-1" src="assets/img/2021-twitter-logo---white-1@2x.png" />
                        <img className="frame-3" src="assets/img/frame-1@2x.svg" />
                    </div>
                    <img className="line-3-1" src="assets/img/line-3@1x.svg" />
                </div>
            </div>
        </div >
    )
}