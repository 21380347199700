//TODO - sit with Appu to catch Insufficient Balance Code
import React, { useEffect, useState, useContext } from "react";
import { connectWallet, getCurrentWalletConnected } from "../utils/connect.js";
import UserContext from '../context/UserContext';
import ContractInstance from "../contracts/contract.js";
const initialAssets = [
    {
        "name": "Contemporary Gold Panchmukhi Ganesha",
        "medium": "Artist Poster Colour, Natural Pigments and Gold Gilding on ELFH",
        "size": "15” x 18”",
        "description": "<p class=\"desc-line-1\">Elfh a patented mineral based composition, a great substitute for Elephant Tusk or  Ivory. Marks the initiative of prohibiting the use of natural bones. Painted in freshly prepared vegetable dyes,  gilded in 24 carat gold is reinstating the disciplines of “Bardashat ka Kaam” meaning the ‘Art of Tolerance’ as  the technique is meticulously practiced under a Magnifying glass.</p><p class=\"desc-line-2\">Refined Tan jore’s, Gold Paintings are a  finer refined art-form of 24crt gold Tan jores fused with Miniatures which are a monopoly worldwide. And  every work done by me is patented, by which it retains its originality &amp; asserts a copyright for all our  prestigious clients.</p>",
        "price": 1.5,
        "image": "/assets/img/1.jpg"
    },
    {
        "name": "Contemporary Gold Ganesha",
        "medium": "Artist Poster Colour, Natural Pigments and Gold Gilding",
        "size": "15” x 18”",
        "description": "<p class=\"desc-line-1\">\"Elfh\" a patented mineral based composition, a great substitute for Elephant Tusk or  Ivory. Marks the initiative of prohibiting the use of natural bones. Painted in freshly prepared vegetable dyes,  gilded in 24 carat gold is reinstating the disciplines of “Bardashat ka Kaam” meaning the ‘Art of Tolerance’ as  the technique is meticulously practiced under a Magnifying glass.</p><p class=\"desc-line-2\"> Refined Tanjore’s, Gold Paintings are a  finer refined art-form of 24crt gold Tanjores fused with Miniatures which are a monopoly worldwide. And  every work done by me is patented, by which it retains its originality & asserts a copyright for all our  prestigious clients.</p>",
        "price": 1.5,
        "image": "/assets/img/2.jpg"
    },
    {
        "name": "Contemporary Gold Ganesha",
        "medium": "Artist Poster Colour, Natural Pigments and Gold Gilding on ELFH",
        "size": "15” x 18”",
        "description": "'<p class=\"desc-line-1\">Elfh' a patented mineral based composition, a great substitute for Elephant Tusk or Ivory. Marks the initiative of prohibiting the use of natural bones. Painted in freshly prepared vegetable dyes, gilded in 24 carat gold is reinstating the disciplines of 'Bardashat ka Kaam' meaning the ‘Art of Tolerance’ as  the technique is meticulously practiced under a Magnifying glass.</p><p class=\"desc-line-2\"> Refined Tanjore’s, Gold Paintings are a finer refined art-form of 24crt gold Tanjores fused with Miniatures  which are a monopoly worldwide. And every work done by me is patented, by which it retains its originality &  asserts a copyright for all our prestigious clients.</p>",
        "price": 1.5,
        "image": "/assets/img/3.jpg"
    },
    {
        "name": "Contemporary Gold Mahalaxmi",
        "medium": "Artist Poster Colour, Natural Pigments and Gold Gilding on ELFH",
        "size": "15” x 18”",
        "description": "<p class=\"desc-line-1\">\"Elfh\" a patented mineral based composition, a great substitute for Elephant Tusk or  Ivory. Marks the initiative of prohibiting the use of natural bones. Painted in freshly prepared vegetable dyes,  gilded in 24 carat gold is reinstating the disciplines of “Bardashat ka Kaam” meaning the ‘Art of Tolerance’ as  the technique is meticulously practiced under a Magnifying glass.</p><p class=\"desc-line-2\"> Refined Tanjore’s, Gold Paintings are a  finer refined art-form of 24crt gold Tanjores fused with Miniatures which are a monopoly worldwide. And  every work done by me is patented, by which it retains its originality & asserts a copyright for all our  prestigious clients.</p>",
        "price": 2,
        "image": "/assets/img/4.jpg"
    },
    {
        "name": "Shrinath ji Swaroop Pichwai",
        "medium": "Artist Poster Colour, Natural Pigments and Gold Gilding on Handmade Canvas",
        "size": "23” x 18”",
        "description": "<p class=\"desc-line-1\">Pichwai is a Sanskrit word in which pich stands for back and wai stands for hanging.  Pichwai paintings are referred to Mewar-style textile paintings that are found hanging on the walls of  temples, houses, art galleries and museums, specifically Nathdwara temple from where they were first  originated. The modern day depiction of Pichwai are made on Handmade Canvases. </p><p class=\"desc-line-2\">Painted in freshly  prepared vegetable dyes, gilded in 24 carat gold is reinstating the disciplines of “Bardashat ka Kaam”  meaning the ‘Art of Tolerance’ as the technique is meticulously practiced under a Magnifying glass. It takes  an year or more to paint one Artwork..</p>",
        "price": 2,
        "image": "/assets/img/5.jpg"
    },
    {
        "name": "Modern day Shrinath ji Swaroop Pichwai",
        "medium": "Artist Poster Colour, Natural Pigments and Gold Gilding on Handmade Canvas",
        "size": "24” x 17”",
        "description": "<p class=\"desc-line-1\">Pichwai is a Sanskrit word in which pich stands for back and wai stands for hanging.  Pichwai paintings are referred to Mewar-style textile paintings that are found hanging on the walls of  temples, houses, art galleries and museums, specifically Nathdwara temple from where they were first  originated. Pichwai is a Sanskrit word in which pich stands for back and wai stands for hanging. </p><p class=\"desc-line-2\">Pichwai  paintings are referred to Mewar-style textile paintings that are found hanging on the walls of temples,  houses, art galleries and museums, specifically Nathdwara temple from where they were first originated.</p>",
        "price": 2,
        "image": "/assets/img/6.jpg"
    },
    {
        "name": "Shree Krishna with Gopi’s Pichwai ",
        "medium": "Artist Poster Colour, Natural Pigments and Gold Gilding on Handmade Canvas",
        "size": "24” x 24”",
        "description": "<p class=\"desc-line-1\">Pichwai is a Sanskrit word in which pich stands for back and wai stands for hanging.  Pichwai paintings are referred to Mewar-style textile paintings that are found hanging on the walls of  temples, houses, art galleries and museums, specifically Nathdwara temple from where they were first  originated. Pichwai is a Sanskrit word in which pich stands for back and wai stands for hanging. </p><p class=\"desc-line-2\">Pichwai  paintings are referred to Mewar-style textile paintings that are found hanging on the walls of temples,  houses, art galleries and museums, specifically Nathdwara temple from where they were first originated.</p>",
        "price": 2.4,
        "image": "/assets/img/7.jpg"
    },
    {
        "name": "Shrinath ji Sarva Swaroop Pichwai",
        "medium": "Artist Poster Colour, Natural Pigments and Gold Gilding on Handmade Canvas",
        "size": "84” x 96”",
        "description": "<p class=\"desc-line-1\">Pichwai is a Sanskrit word in which pich stands for back and wai stands for hanging.  Pichwai paintings are referred to Mewar-style textile paintings that are found hanging on the walls of  temples, houses, art galleries and museums, specifically Nathdwara temple from where they were first  originated. Pichwai is a Sanskrit word in which pich stands for back and wai stands for hanging. </p><p class=\"desc-line-2\">Pichwai  paintings are referred to Mewar-style textile paintings that are found hanging on the walls of temples,  houses, art galleries and museums, specifically Nathdwara temple from where they were first originated..</p>",
        "price": 2.5,
        "image": "/assets/img/8.jpg"
    },
    {
        "name": "Riddhi Siddhi Ganesha Refined Tanjore ",
        "medium": "Artist Poster Colour, Natural Pigments and Gold Gilding on Wood Size ",
        "size": "36” x 48”",
        "description": "<p class=\"desc-line-1\">Refined Tanjore’s, Gold Paintings are a finer refined art-form of 24crt gold Tanjores  fused with Miniatures which are a monopoly worldwide. And every work done by me is patented, by which it  retains its originality & asserts a copyright for all our prestigious clients..</p>",
        "price": 4,
        "image": "/assets/img/9.jpg"
    },
    {
        "name": "Govardhan Parvat on ELFH",
        "medium": "Artist Poster Colour, Natural Pigments and Gold Gilding on Handmade Canvas",
        "size": "18” x 24”",
        "description": "<p class=\"desc-line-1\">Suvigya to have practiced miniature art since his childhood and mastering Refined  Tanjores, Miniatures, Pichwai’s for about a decade now. This year brings forth the feature artworks of Shrinath Ji s Contemporary Pichwai s that would grace an aesthetes personal residence along with, the 3  Dimensional Govardhan Parvat, a celebratory reminiscence depicting the supernatural act of Lord Krishnas,  eighth avatar of the god Vishnu lifting Govardhan Parvat for 7 Days and Nights. </p><p class=\"desc-line-2\">As the God of Heavens Indra  calls Samavartaka clouds of devastation, to lash upon Vrindavan with torrents of rain and thunderstorms  and cause extensive floods that would destroy the livelihood of the inhabitants.</p>",
        "price": 4,
        "image": "/assets/img/10.jpg"
    },
    {
        "name": "Radha Krishna Miniature Painting",
        "medium": "Artist Poster Colour, Natural Pigments and Gold Gilding on Handmade Canvas",
        "size": "23” x 36”",
        "description": "<p class=\"desc-line-1\">The Reminiscence collection is an epic saga highlighting the Hindu pantheon and it’s  timeless grace that set foundation for the aesthetics of modern contemporary art, worldwide. </p><p class=\"desc-line-2\">The man  need no introduction as he is the world’s most disciplined miniature artist, to have practiced Traditional fine  miniature art under the lineage of his father the legendary Fine Artist Mr. R. K. Sharma, descending from the  masters of Nathdwara Shaili Miniature paintings.</p>",
        "price": 7,
        "image": "/assets/img/11.jpg"
    },
    {
        "name": "Shrinath ji Sarva Swaroop",
        "medium": "Artist Poster Colour, Natural Pigments and Gold Gilding on Handmade Canvas",
        "size": "84” x 96”",
        "description": "<p class=\"desc-line-1\">Pichwai is a Sanskrit word in which pich stands for back and wai stands for hanging.  Pichwai paintings are referred to Mewar-style textile paintings that are found hanging on the walls of  temples, houses, art galleries and museums, specifically Nathdwara temple from where they were first  originated. </p><p class=\"desc-line-2\"> The modern day depiction of Pichwai are made on Handmade Canvases. Painted in freshly prepared  vegetable dyes, gilded in 24 carat gold is reinstating the disciplines of “Bardashat ka Kaam” meaning the ‘Art  of Tolerance’ as the technique is meticulously practiced under a Magnifying glass. It takes an year or more  to paint one Artwork.</p>",
        "price": 7,
        "image": "/assets/img/12.jpg"
    }
];

function MintSection(props) {
    const { setAssetId } = useContext(UserContext);
    const [wallet, setWallet] = useState("");
    const [ownedTokens, setOwnedTokens] = useState({});
    const [assets, setAssets] = useState(initialAssets)

    const addWalletListener = async () => {
        console.error("Attaching addWalletListener");
        if (window.ethereum) {
            console.error("Attaching addWalletListener", window.ethereum);

            window.ethereum.on("accountsChanged", accounts => {
                console.error("addWalletListener", accounts);
                setWallet(accounts.length > 0 ? accounts[0] : '');
                validate(true);
            });
        }
    };

    const isSaleActive = async () => {
        const wallet = await getCurrentWalletConnected();
        if (!wallet) {
            return true;
        }
        let valid = false
        try {
            valid = await ContractInstance.methods.isActive().call();
        } catch (e) {
            console.error("isSaleActive", e);
            return true;
        }
        return valid
    }

    const isTokenValid = async (assetId) => {
        const wallet = await getCurrentWalletConnected();
        if (!wallet) {
            return true;
        }
        let valid = false
        try {
            valid = await ContractInstance.methods.isTokenActive(assetId).call();
            console.error("isTokenValid - From Contract on ETH", assetId, valid);
        } catch (e) {
            console.error("error on isTokenValid", e);
            return true;
        }
        return valid
    }

    const updateBalance = async () => {
        try {
            let balance = await ContractInstance.methods.balanceOf(await getCurrentWalletConnected()).call()
            const _ownedTokens = await getOwnedTokens(balance, wallet);
            return _ownedTokens;
        } catch (e) {
            console.log(`Balance Retrieval Failed. ${e}`)
        }
    };

    const getOwnedTokens = async (balance) => {
        const _ownedTokens = {}
        for (let i = 0; i < balance; i++) {
            let tokenId = await ContractInstance.methods.tokenOfOwnerByIndex(await getCurrentWalletConnected(), i).call();
            _ownedTokens[tokenId] = true;
        }
        setOwnedTokens(_ownedTokens)
        return _ownedTokens;
    };

    useEffect(() => {
        const fetchWallet = async () => setWallet(await getCurrentWalletConnected());
        fetchWallet();
        updateBalance();
        addWalletListener();
        validate();
    }, []);

    let { assetId, innerRef } = props;
    assetId = parseInt(assetId);

    const validate = async (force) => {
        const wallet = await getCurrentWalletConnected();
        console.error("validate", wallet);
        if (!wallet) {
            for (let i = 0; i < assets.length; i++) {
                assets[i]['buttonText'] = "Please connect your wallet";
                assets[i]['buttonClass'] = "mint-now-btn"
                assets[i]['buttonDisabled'] = false;
            }
            var copy = assets.slice();
            setAssets(copy);
            return;
        }
        const _ownedTokens = await updateBalance();
        for (let i = 0; i < assets.length; i++) {
            if (force) {
                assets[i]['checked'] = false;
            }
            if (assets[i]['checked']) {
                continue;
            }
            assets[i]['checked'] = true;
            assets[i]['buttonText'] = "Loading...";
            assets[i]['buttonClass'] = "mint-now-btn-disabled"
            assets[i]['buttonDisabled'] = true;
            console.error("_ownedTokens", i, _ownedTokens[i]);

            if (_ownedTokens[i]) {
                assets[i]['buttonClass'] = 'mint-now-btn-disabled'
                assets[i]['buttonDisabled'] = true
                assets[i]['buttonText'] = "Congrats!"
                continue;
            }

            let valid = await isSaleActive()
            console.error(i, "isSaleActive", valid);
            if (!valid) {
                assets[i]['buttonText'] = "Sale not yet started";
                assets[i]['buttonClass'] = "mint-now-btn-disabled"
                assets[i]['buttonDisabled'] = true;
                continue;
            }

            valid = await isTokenValid(i)
            console.error(i, "isTokenValid return", valid);
            if (!valid) {
                assets[i]['buttonText'] = "Sold!";
                assets[i]['buttonClass'] = "mint-now-btn-disabled"
                assets[i]['buttonDisabled'] = true;
                continue;
            }

            try {
                await ContractInstance.methods.mint(i).estimateGas({
                    from: await getCurrentWalletConnected(),
                    value: assets[i]['price'] * 1000000000000000000,
                });
                assets[i]['buttonText'] = "Mint My Own";
                assets[i]['buttonClass'] = "mint-now-btn"
                assets[i]['buttonDisabled'] = false;
            } catch (e) {
                assets[i]['buttonText'] = "Insufficient Funds"
                assets[i]['buttonClass'] = "mint-now-btn-disabled"
                assets[i]['buttonDisabled'] = true
            }
        }
        var copy = assets.slice();
        setAssets(copy);

    };

    if (assetId < 0 || assetId >= assets.length) {
        assetId = 0;
    }

    // TODO - Please log in message after successful transaction.
    const mint = async (e) => {
        e.preventDefault();
        const wallet = await connectWallet();
        // wallet = await getCurrentWalletConnected();
        if (!wallet) {
            console.error("No Wallet")
            // const url = "https://metamask.app.link/dapp/suvigya.rtalabs.xyz";
            // window.open(url, '_blank');
            return;
        }
        // Set button as disabled until the transacton is complete
        assets[assetId]['buttonDisabled'] = true;
        let amount = assets[assetId]['price'];
        let gas = await ContractInstance.methods.mint(assetId).estimateGas({
            from: wallet,
            value: assets[assetId]['price'] * 1000000000000000000,
        });

        gas = Number.parseInt(gas * 1.1);
        let result = await ContractInstance.methods.mint(assetId).send({
            from: wallet,
            value: assets[assetId]['price'] * 1000000000000000000,
            gasLimit: gas
        });
        if (result.transactionHash) {
            await validate(true);
        }
        console.error(result.transactionHash);
    }

    const nextAsset = async () => {
        setAssetId((assetId + 1) % assets.length)
    }

    const prevAsset = async () => {
        setAssetId(Math.abs(assetId - 1) % assets.length)
    }

    const getHTML = (markup) => {
        return {
            '__html': markup
        }
    }
    const buttonText = assets[assetId]['buttonText'];
    const buttonClass = assets[assetId]['buttonClass'];
    const isButtonDisabled = assets[assetId]['buttonDisabled'];
    const imageUrl = "https://res.cloudinary.com/dd6p5cxht/image/upload/w_1920,q_95,fl_progressive,f_webp/v1634664658/SUVZ/"+ (assetId + 1) +".jpg"
    return (
        <div className="mint-container">
            <div className="mint-image">
                <img src={imageUrl} key={imageUrl} width="600" />
                {assetId != 0 && <div className="prev-btn" onClick={prevAsset}>Previous</div>}
                {assetId != 11 && <div className="next-btn" onClick={nextAsset}>Next</div>}
            </div>
            <div className="mint-content">
                <div className="mint-type">NFT + PHYSICAL PAINTING</div>
                <div className="mint-name">{assets[assetId]['name']} </div>
                <div className="mint-asset-info">
                    <p>Medium - {assets[assetId]['medium']}</p>
                    <p>Size {assets[assetId]['size']}</p>
                </div>
                <div className="mint-description" dangerouslySetInnerHTML={getHTML(assets[assetId]['description'])} />
                <div className="mint-price">
                    {assets[assetId]['price']} ETH
                </div>
                <button className={buttonClass} onClick={mint} disabled={isButtonDisabled} >
                    {buttonText}
                </button>
                <div className="mint-instrcution">
                    <div className="instruction">
                        <p className="ellipse" />
                        Connect your Metamask Wallet
                    </div>
                    <div className="instruction">
                        <p className="ellipse" />
                        Choose Mint My Own
                    </div>
                    <div className="instruction">
                        <p className="ellipse" />
                        Choose the NFT (Painting you want to mint)
                    </div>
                    <div className="instruction">
                        <p className="ellipse" />
                        Pay the Amount and Get the NFT in your wallet
                    </div>
                </div>
                <div className="mint-now-disclaimer">
                    Once minted, it cannot be withdrawn
                </div>
            </div>
        </div>
    );
};

export default MintSection;