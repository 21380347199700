// third party imports
import React from "react";
import ReactDOM from "react-dom";

// local imports
import App from "./App";
import "./style/style.css";
import "./style/fontawesome.min.css";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
