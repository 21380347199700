// third party imports
import React from "react";

// local imports

import Home from './pages/Home.js';

function App() {

  return (
    <div className="app">
        <Home />
    </div>
  );
};

export default App;
